export const theme = {
    colors: {
      lightGrey: "#F5F6F8",
      lightGreen: "#6aa940",
      midleBlack: "#4F4A4A",
      white: "#ffffff",
      black: "black",
      red: "red",
      lightGreenHover: "#629a3d",
    },
    fontWeight: {
      fontWeightBold: 900,
      fontWeightMedium: 700,
      fontWeightLight: 600,
    },
    device: {
      phone: "576px",
      tablet: "768px",
      desktop: "1920px",
    },
  };
  