import { motion } from "framer-motion";
import {Link} from "gatsby";
import styled from "styled-components";


export const FooterKontaktSection = styled.div`
width:100% ;
background-color: ${({ theme }) => theme.colors.white};

`;

export const FooterKontaktWrapper = styled(motion.div)`

width: 100%;
max-width: 1534px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${({ theme }) => theme.colors.white};
margin: 0 auto;
padding-left: 5%;
padding-right: 5%;
scroll-margin: 100px 0 0 80px;


@media screen and (max-width: 768px) {
padding-left: 5%;
padding-right: 5%;
}
`;


export const NumberTel = styled(Link)`
font-family: 'Montserrat', sans-serif;
font-size: 22px;
text-decoration:none;
padding-bottom:10px;
color:  ${({ theme }) => theme.colors.black};
font-weight: ${({ theme }) => theme.fontWeight.fontWeightBold};

&:hover {
  color: ${({ theme }) => theme.colors.lightGreen};
}
`;

export const Text = styled.p`
font-family: 'Montserrat', sans-serif;
font-size: clamp(0.9rem, calc(0.9rem + ((1vw - 0.36rem) * 0.119)), 1rem);
  min-height: 0vw;
line-height: 1.5;
text-align: center;
`;

export const TitleKontakt = styled.h2`
font-family: 'Montserrat', sans-serif;
font-size: clamp(1.6rem, calc(1.6rem + ((1vw - 0.36rem) * 1.0714)), 2.5rem);
  min-height: 0vw;
font-weight:${({ theme }) => theme.fontWeight.fontWeightBold};
color: ${({ theme }) => theme.colors.lightGreen};
padding-top: 30px;
text-align: center;
`;

export const LinkStyled = styled(Link)`
display: inline-block ;
font-weight: ${({ theme }) => theme.fontWeight.fontWeightMedium};
padding-top: 10px;
font-size: 1rem;
&:hover {
  color: ${({ theme }) => theme.colors.lightGreen};
}
`;


export const BoxFooter = styled(motion.div)`
font-family: 'Montserrat', sans-serif;
width: 100%;
text-align: center;
font-size: 0.9rem;
display: flex;
flex-direction:column ;

a{
    color:${({ theme }) => theme.colors.black};
    padding-bottom: 10px;
    text-decoration: none;
    font-size: 0.9rem;
  
}
`;
export const CopyrightText =styled.div`

@media screen and (max-width: 768px) {
display:flex ;
flex-direction:column ;
justify-content:center;
text-align: center;
}
`