import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, useStaticQuery, graphql } from "gatsby"

const LogoWrap = styled.div`
  margin-top:-5px;
  flex: 0 1 230px;

  @media (max-width: 768px) /*and (orientation: landscape) */{
    flex: 0 1 180px;
padding-top: 5px;
   
  }`


const Logo = () => {
  const data = useStaticQuery(graphql`{
  file(name: {eq: "gatsby-icon"}, extension: {eq: "png"}) {
    childImageSharp {
      gatsbyImageData(width: 230,
      layout: CONSTRAINED, placeholder: NONE)
    }
  }
}
`)

  return (
    <LogoWrap as={Link} to="/">
      <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="logo" />
      
    </LogoWrap>
  );
}

export default Logo
