import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { FaHome} from "react-icons/fa";
import { IconContext } from "react-icons";



const NavItem = styled(Link)`
font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  color: black;
  font-weight: 700;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  

  img{
   padding-top:20px;
 }

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: red;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color:green;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`


 

const NavbarLinks = () => {
  
  return (
    <>
      <NavItem to="/">
      <IconContext.Provider value={{ style: { color: '#6aa940', verticalAlign: 'middle', width:'38px', height:'38px'}}}>
      <FaHome />
      </IconContext.Provider>
        </NavItem>
        

      <NavItem to="/#o-nas">O nas</NavItem >
      <NavItem to="/#uslugi">Usługi</NavItem>
      <NavItem to="/kontakt">Kontakt</NavItem >
    </>
  )
}

export default NavbarLinks
